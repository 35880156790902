import React, { useState, useCallback, useRef, useEffect } from 'react';
import quantize from 'quantize';

const StyledButton = ({ onClick, children, style }) => (
  <button
    onClick={onClick}
    style={{
      backgroundColor: '#e0e0e0',
      border: 'none',
      borderRadius: '20px',
      padding: '8px 16px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#333',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      ...style
    }}
    onMouseOver={(e) => e.target.style.backgroundColor = '#d0d0d0'}
    onMouseOut={(e) => e.target.style.backgroundColor = '#e0e0e0'}
  >
    {children}
  </button>
);

const ColorSwatch = ({ color, onCopy, isPlaceholder }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      style={{ 
        width: 'calc(50% - 20px)', // 2 swatches per row on mobile
        maxWidth: '100px',
        height: '120px', 
        backgroundColor: 'white', 
        borderRadius: '10px', 
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', 
        margin: '10px', 
        position: 'relative', 
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={() => onCopy(color)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
        <div style={{ 
          width: '100%', 
          height: '80%', 
          backgroundColor: color, 
          borderTopLeftRadius: '10px', 
          borderTopRightRadius: '10px' 
        }} />
        <div style={{ 
          padding: '5px', 
          textAlign: 'center', 
          fontSize: '12px' 
        }}>
          {color.toUpperCase()}
        </div>
        {isHovered && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '80%',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '14px',
            borderTopLeftRadius: '10px', 
            borderTopRightRadius: '10px'
          }}>
            Copy
          </div>
        )}
      </div>
  );
};

const UploadArea = ({ onImageUpload }) => {
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onImageUpload(e.dataTransfer.files[0]);
    }
  };

  return (
    <div
      onClick={() => fileInputRef.current.click()}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        width: '100%',
        maxWidth: '300px',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '10px',
      }}
    >
      <div style={{ fontSize: '40px', marginBottom: '10px' }}>📁</div>
      <div>Click or Drag to Upload Image</div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => onImageUpload(e.target.files[0])}
        accept="image/*"
        style={{ display: 'none' }}
      />
    </div>
  );
};

const ColorPaletteGenerator = () => {
  const [image, setImage] = useState(null);
  const [fullPalette, setFullPalette] = useState([]);
  const [displayPalette, setDisplayPalette] = useState([]);
  const [colorCount, setColorCount] = useState(4);
  const [placeholderPalette, setPlaceholderPalette] = useState([]);

  const generateRandomColor = () => {
    return '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
  };

  useEffect(() => {
    const initialPlaceholders = Array(7).fill().map(() => generateRandomColor());
    setPlaceholderPalette(initialPlaceholders);
    setDisplayPalette(initialPlaceholders.slice(0, colorCount));
  }, []);

  const handleImageUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        generatePalette(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    setFullPalette([]);
    setDisplayPalette(placeholderPalette.slice(0, colorCount));
  };

  const generatePalette = useCallback((imageData) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;
      const colorMap = [];
      
      const sampleSize = Math.min(50000, pixels.length / 4);
      const sampledIndexes = new Set();
      while (sampledIndexes.size < sampleSize) {
        const randomIndex = Math.floor(Math.random() * (pixels.length / 4)) * 4;
        if (!sampledIndexes.has(randomIndex)) {
          sampledIndexes.add(randomIndex);
          const r = pixels[randomIndex];
          const g = pixels[randomIndex + 1];
          const b = pixels[randomIndex + 2];
          colorMap.push([
            Math.min(255, Math.max(0, r + (Math.random() - 0.5) * 20)),
            Math.min(255, Math.max(0, g + (Math.random() - 0.5) * 20)),
            Math.min(255, Math.max(0, b + (Math.random() - 0.5) * 20))
          ]);
        }
      }

      const colorQuantization = quantize(colorMap, 7 * 2);
      let paletteColors = colorQuantization.palette().slice(0, 7 * 2);
      
      paletteColors = paletteColors
        .sort(() => Math.random() - 0.5)
        .slice(0, 7);

      const newPalette = paletteColors.map(color => `#${Math.round(color[0]).toString(16).padStart(2, '0')}${Math.round(color[1]).toString(16).padStart(2, '0')}${Math.round(color[2]).toString(16).padStart(2, '0')}`);
      setFullPalette(newPalette);
      setDisplayPalette(newPalette.slice(0, colorCount));
    };
    img.src = imageData;
  }, [colorCount]);

  const generateNewPalette = () => {
    if (image) {
      generatePalette(image);
    }
  };

  const updateDisplayPalette = useCallback((count) => {
    if (image && fullPalette.length > 0) {
      setDisplayPalette(fullPalette.slice(0, count));
    } else {
      setDisplayPalette(placeholderPalette.slice(0, count));
    }
  }, [fullPalette, placeholderPalette, image]);

  const handleColorCountChange = (newCount) => {
    setColorCount(newCount);
    updateDisplayPalette(newCount);
  };

  const copyColor = (color) => {
    navigator.clipboard.writeText(color).then(() => {
      // You can replace this alert with a more subtle notification if desired
      alert(`Copied ${color} to clipboard!`);
    });
  };

  const copyAllColors = () => {
    if (image) {
      const allColors = displayPalette.join(', ');
      navigator.clipboard.writeText(allColors).then(() => {
        alert('Copied all colors to clipboard!');
      });
    } else {
      alert('Please upload an image to generate a real color palette.');
    }
  };

  useEffect(() => {
    updateDisplayPalette(colorCount);
  }, [colorCount, updateDisplayPalette]);

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%',
      margin: 0,
      padding: 0,
    }}>
      <div className="color-palette-generator" style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        width: '100%',
        maxWidth: '600px', 
        margin: '0 auto',
        padding: '20px',
        color: '#212529',
        boxSizing: 'border-box',
      }}>
        <h1 style={{ color: '#212529', marginBottom: '30px', fontSize: 'calc(24px + 2vmin)' }}>Generate Your Palette</h1>
      
        {image ? (
          <div style={{ marginBottom: '20px', textAlign: 'center', width: '100%' }}>
            <img src={image} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px', marginBottom: '20px' }} />
            <div style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <StyledButton onClick={removeImage} style={{ marginRight: '15px', marginBottom: '10px' }}>⬅️ Remove Image</StyledButton>
              <StyledButton onClick={generateNewPalette} style={{ marginBottom: '10px' }}>🔄 Regenerate</StyledButton>
            </div>
          </div>
        ) : (
          <UploadArea onImageUpload={handleImageUpload} />
        )}
      
        <div style={{ width: '90%', marginBottom: '20px', fontWeight: '600', fontSize:'16px'}}>
          <label htmlFor="colorCount" style={{ display: 'block', marginBottom: '5px' }}>Number of colors: {colorCount}</label>
          <input 
            type="range" 
            id="colorCount" 
            min="3" 
            max="7" 
            value={colorCount} 
            onChange={(e) => handleColorCountChange(Number(e.target.value))}
            className="custom-range"
          />
        </div>
      
        <div className="color-swatches custom-scrollbar" style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
        }}>
          {displayPalette.map((color, index) => (
            <ColorSwatch 
              key={index} 
              color={color} 
              onCopy={copyColor} 
              isPlaceholder={!image} 
            />
          ))}
        </div>
      
        <StyledButton onClick={copyAllColors} style={{ marginTop: '40px', marginBottom: '20px', width: '100%', maxWidth: '200px' }}>
          Copy All ↗️
        </StyledButton>
      </div>

      <div className="footer" style={{
        width: '100%',
        backgroundColor: '#151515',
        color: 'white',
        textAlign: 'center',
        padding: '25px 0px 25px 0px',
        fontSize: '15px',
        fontWeight: '500',
        borderTop: '1px solid lightgray'
      }}>
        © 2024 Midnight Waters. All rights reserved.
      </div>

      <style jsx>{`
        .custom-scrollbar::-webkit-scrollbar {
          height: 12px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        
        .custom-range {
          -webkit-appearance: none;
          width: 100%;
          height: 10px;
          border-radius: 5px;
          background: #d3d3d3;
          outline: none;
          opacity: 0.7;
          transition: opacity .2s;
          margin: 10px 0; /* Add some vertical margin */
        }
        
        .custom-range::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #444;
          cursor: pointer;
          margin-top: -5px; /* Center the thumb vertically */
        }
        
        .custom-range::-moz-range-thumb {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: #444;
          cursor: pointer;
          /* Firefox automatically centers the thumb */
        }
        
        .custom-range::-webkit-slider-runnable-track {
          height: 10px;
          border-radius: 5px;
          background: linear-gradient(to right, #444 0%, #444 ${(colorCount - 3) * 25}%, #d3d3d3 ${(colorCount - 3) * 25}%, #d3d3d3 100%);
        }
        
        .custom-range::-moz-range-track {
          height: 10px;
          border-radius: 5px;
          background: linear-gradient(to right, #444 0%, #444 ${(colorCount - 3) * 25}%, #d3d3d3 ${(colorCount - 3) * 25}%, #d3d3d3 100%);
        }

        /* Ensure the track doesn't change height when hovering over the thumb */
        .custom-range::-webkit-slider-runnable-track:hover,
        .custom-range::-moz-range-track:hover {
          height: 10px;
        }
        
        :global(body) {
          margin: 0;
          padding: 0;
          font-family: Arial, sans-serif;
        }
        
        @media (max-width: 600px) {
          .color-palette-generator {
            padding: 20px 10px;
          }

        .color-palette-generator {
          box-sizing: border-box;
        }
      `}</style>
    </div>
  );
};

export default ColorPaletteGenerator;